import { ListCustom } from "./response-custom";
import { getDateAgo } from "../helper/date";
import { CommonModel } from "./tab-module";

export interface DataLogData {
  id: number;
  sn: string;
  mac: string;
  error: string;
  status: string;
  version: string;
  logTime: Date;
  datas_A_PhaseVoltage: number;
  datas_A_Current: number;
  datas_A_Power: number;
  datas_A_ImportEnergy: number;
  datas_A_ExportEnergy: number;
  datas_A_Frequency: number;
  datas_A_PF: number;
  datas_B_PhaseVoltage: number;
  datas_B_Current: number;
  datas_B_Power: number;
  datas_B_ImportEnergy: number;
  datas_B_ExportEnergy: number;
  datas_B_Frequency: number;
  datas_B_PF: number;
  datas_C_PhaseVoltage: number;
  datas_C_Current: number;
  datas_C_Power: number;
  datas_C_ImportEnergy: number;
  datas_C_ExportEnergy: number;
  datas_C_Frequency: number;
  datas_C_PF: number;
  datas_D_PhaseVoltage: number;
  datas_D_Current: number;
  datas_D_Power: number;
  datas_D_ImportEnergy: number;
  datas_D_ExportEnergy: number;
  datas_D_Frequency: number;
  datas_D_PF: number;
}

export class DataLogTable {
  page: number;
  size: number;
  total: number;
  data: DataLogData[];
}

export enum DeviceDropdownSuggession {
  deviceIds = "deviceIds",
  residenceId = "residenceId",
}

export interface DataLogFilterParams {
  residenceId: number;
  deviceSNs?: Array<string>;
  fromDate: string;
  toDate: string;
  residence?: CommonModel;
  deviceIds?: CommonModel[];
}

export interface DataLogList {
  data?: ListCustom;
  fromDate: string;
  toDate: string;
  residenceId: number;
  deviceSNs?: Array<string>;
}

export enum TimeFrameKey {
  Last24Hr = 1,
  Last7Days = 2,
  LastMonth = 3,
  LastYear = 4,
  Today = 5,
  // CurrentWeek = 6,
  // CurrentMonth = 7,
  // CurrentYear = 8,
  Custom = 5,
  DayPerMinute = 6,
  DayPer15Minute = 7,
}

export enum DatePickerId {
  FromDate = "fromDate",
  ToDate = "toDate",
}

export const TIME_FRAME_MAP = {
  [TimeFrameKey.Last24Hr]: {
    fromDate: getDateAgo("day", 1),
    toDate: getDateAgo("day", 1, false),
  },
  [TimeFrameKey.Last7Days]: {
    fromDate: getDateAgo("isoWeek", 0),
    toDate: getDateAgo("isoWeek", 0, false),
  },
  [TimeFrameKey.LastMonth]: {
    fromDate: getDateAgo("month", 0),
    toDate: getDateAgo("month", 0, false),
  },
  [TimeFrameKey.LastYear]: {
    fromDate: getDateAgo("year", 0),
    toDate: getDateAgo("year", 0, false),
  },
  [TimeFrameKey.Today]: {
    fromDate: getDateAgo("day", 0),
    toDate: getDateAgo("day", 0, false),
  },
  // [TimeFrameKey.CurrentWeek]: {
  //   fromDate: getDateAgo("isoWeek", 0),
  //   toDate: getDateAgo("isoWeek", 0, false),
  // },
  // [TimeFrameKey.CurrentMonth]: {
  //   fromDate: getDateAgo("month", 0),
  //   toDate: getDateAgo("month", 0, false),
  // },
  // [TimeFrameKey.CurrentYear]: {
  //   fromDate: getDateAgo("year", 0),
  //   toDate: getDateAgo("year", 0, false),
  // },
  default: {
    fromDate: getDateAgo("day", 0),
    toDate: getDateAgo("day", 0, false),
  },
};

export interface DataLogFilter {
  id: number;
  label: string;
  active: boolean;
}

export enum DataLogTimeFrameKey {
  LAST24HR = 1,
  LAST7DAYS = 2,
  LASTMONTH = 3,
  CUSTOM = 4,
}

export const DataLogTimeFrame: DataLogFilter[] = [
  {
    id: DataLogTimeFrameKey.LAST24HR,
    label: "Last 24 hr",
    active: true,
  },
  {
    id: DataLogTimeFrameKey.LAST7DAYS,
    label: "Last 7 Days",
    active: false,
  },
  {
    id: DataLogTimeFrameKey.LASTMONTH,
    label: "Last Month",
    active: false,
  },
  {
    id: DataLogTimeFrameKey.CUSTOM,
    label: "Custom",
    active: false,
  },
];

export enum ColumnName {
  ID = "Id",
  SN = "SN",
  MAC = "MAC",
  ERROR = "Error",
  STATUS = "Status",
  VERSION = "Version",
  LOG_TIME_UTC = "Log Time",
  A_PHASE_VOLTAGE = "A Phase Volatage",
  A_CURRENT = "A Current",
  A_POWER = "A Power",
  A_IMPORT_ENERGY = "A Import Energy",
  A_EXPORT_ENERGY = "A Export Energy",
  A_FREQUENCY = "A Frequwncy",
  A_PF = "A PF",
  B_PHASE_VOLTAGE = "B Phase Volatage",
  B_CURRENT = "B Current",
  B_POWER = "B Power",
  B_IMPORT_ENERGY = "B Import Energy",
  B_EXPORT_ENERGY = "B Export Energy",
  B_FREQUENCY = "B Frequwncy",
  B_PF = "B PF",
  C_PHASE_VOLTAGE = "C Phase Volatage",
  C_CURRENT = "C Current",
  C_POWER = "C Power",
  C_IMPORT_ENERGY = "C Import Energy",
  C_EXPORT_ENERGY = "C Export Energy",
  C_FREQUENCY = "C Frequwncy",
  C_PF = "C PF",
  D_PHASE_VOLTAGE = "D Phase Volatage",
  D_CURRENT = "D Current",
  D_POWER = "D Power",
  D_IMPORT_ENERGY = "D Import Energy",
  D_EXPORT_ENERGY = "D Export Energy",
  D_FREQUENCY = "D Frequwncy",
  D_PF = "D PF",
}

export interface ColumnShowHide {
  name: string;
  hide: boolean;
  key?: string;
}

export enum DataLogEKeyField {
  id = "id",
  sn = "sn",
  status = "status",
  logTimeUTC = "logTimeUTC",
  datas_A_PhaseVoltage = "datas_A_PhaseVoltage",
  datas_A_Current = "datas_A_Current",
  datas_A_Power = "datas_A_Power",
  datas_A_ImportEnergy = "datas_A_ImportEnergy",
  datas_A_ExportEnergy = "datas_A_ExportEnergy",
  datas_A_Frequency = "datas_A_Frequency",
  datas_A_PF = "datas_A_PF",
  datas_B_PhaseVoltage = "datas_B_PhaseVoltage",
  datas_B_Current = "datas_B_Current",
  datas_B_Power = "datas_B_Power",
  datas_B_ImportEnergy = "datas_B_ImportEnergy",
  datas_B_ExportEnergy = "datas_B_ExportEnergy",
  datas_B_Frequency = "datas_B_Frequency",
  datas_B_PF = "datas_B_PF",
  datas_C_PhaseVoltage = "datas_C_PhaseVoltage",
  datas_C_Current = "datas_C_Current",
  datas_C_Power = "datas_C_Power",
  datas_C_ImportEnergy = "datas_C_ImportEnergy",
  datas_C_ExportEnergy = "datas_C_ExportEnergy",
  datas_C_Frequency = "datas_C_Frequency",
  datas_C_PF = "datas_C_PF",
  datas_D_PhaseVoltage = "datas_D_PhaseVoltage",
  datas_D_Current = "datas_D_Current",
  datas_D_Power = "datas_D_Power",
  datas_D_ImportEnergy = "datas_D_ImportEnergy",
  datas_D_ExportEnergy = "datas_D_ExportEnergy",
  datas_D_Frequency = "datas_D_Frequency",
  datas_D_PF = "datas_D_PF",
  mac = "mac",
  error = "error",
  version = "version",
}

export const DataLogColumns: ColumnShowHide[] = [
  {
    name: ColumnName.SN,
    hide: false,
    key: DataLogEKeyField.sn,
  },
  {
    name: ColumnName.MAC,
    hide: false,
    key: DataLogEKeyField.mac,
  },
  {
    name: ColumnName.ERROR,
    hide: false,
    key: DataLogEKeyField.error,
  },
  {
    name: ColumnName.VERSION,
    hide: false,
    key: DataLogEKeyField.version,
  },
  {
    name: ColumnName.LOG_TIME_UTC,
    hide: false,
    key: DataLogEKeyField.logTimeUTC,
  },
  {
    name: ColumnName.A_PHASE_VOLTAGE,
    hide: false,
    key: DataLogEKeyField.datas_A_PhaseVoltage,
  },
  {
    name: ColumnName.A_CURRENT,
    hide: false,
    key: DataLogEKeyField.datas_A_Current,
  },
  {
    name: ColumnName.A_POWER,
    hide: false,
    key: DataLogEKeyField.datas_A_Power,
  },
  {
    name: ColumnName.A_IMPORT_ENERGY,
    hide: false,
    key: DataLogEKeyField.datas_A_ImportEnergy,
  },
  {
    name: ColumnName.A_EXPORT_ENERGY,
    hide: false,
    key: DataLogEKeyField.datas_A_ExportEnergy,
  },
  {
    name: ColumnName.A_FREQUENCY,
    hide: false,
    key: DataLogEKeyField.datas_A_Frequency,
  },
  {
    name: ColumnName.A_PF,
    hide: false,
    key: DataLogEKeyField.datas_A_PF,
  },
  {
    name: ColumnName.B_PHASE_VOLTAGE,
    hide: false,
    key: DataLogEKeyField.datas_B_PhaseVoltage,
  },
  {
    name: ColumnName.B_CURRENT,
    hide: false,
    key: DataLogEKeyField.datas_B_Current,
  },
  {
    name: ColumnName.B_POWER,
    hide: false,
    key: DataLogEKeyField.datas_B_Power,
  },
  {
    name: ColumnName.B_IMPORT_ENERGY,
    hide: false,
    key: DataLogEKeyField.datas_B_ImportEnergy,
  },
  {
    name: ColumnName.B_EXPORT_ENERGY,
    hide: false,
    key: DataLogEKeyField.datas_B_ExportEnergy,
  },
  {
    name: ColumnName.B_FREQUENCY,
    hide: false,
    key: DataLogEKeyField.datas_B_Frequency,
  },
  {
    name: ColumnName.B_PF,
    hide: false,
    key: DataLogEKeyField.datas_B_PF,
  },
  {
    name: ColumnName.C_PHASE_VOLTAGE,
    hide: false,
    key: DataLogEKeyField.datas_C_PhaseVoltage,
  },
  {
    name: ColumnName.C_CURRENT,
    hide: false,
    key: DataLogEKeyField.datas_C_Current,
  },
  {
    name: ColumnName.C_POWER,
    hide: false,
    key: DataLogEKeyField.datas_C_Power,
  },
  {
    name: ColumnName.C_IMPORT_ENERGY,
    hide: false,
    key: DataLogEKeyField.datas_C_ImportEnergy,
  },
  {
    name: ColumnName.C_EXPORT_ENERGY,
    hide: false,
    key: DataLogEKeyField.datas_C_ExportEnergy,
  },
  {
    name: ColumnName.C_FREQUENCY,
    hide: false,
    key: DataLogEKeyField.datas_C_Frequency,
  },
  {
    name: ColumnName.C_PF,
    hide: false,
    key: DataLogEKeyField.datas_C_PF,
  },
  {
    name: ColumnName.D_PHASE_VOLTAGE,
    hide: false,
    key: DataLogEKeyField.datas_D_PhaseVoltage,
  },
  {
    name: ColumnName.D_CURRENT,
    hide: false,
    key: DataLogEKeyField.datas_D_Current,
  },
  {
    name: ColumnName.D_POWER,
    hide: false,
    key: DataLogEKeyField.datas_D_Power,
  },
  {
    name: ColumnName.D_IMPORT_ENERGY,
    hide: false,
    key: DataLogEKeyField.datas_D_ImportEnergy,
  },
  {
    name: ColumnName.D_EXPORT_ENERGY,
    hide: false,
    key: DataLogEKeyField.datas_D_ExportEnergy,
  },
  {
    name: ColumnName.D_FREQUENCY,
    hide: false,
    key: DataLogEKeyField.datas_D_Frequency,
  },
  {
    name: ColumnName.D_PF,
    hide: false,
    key: DataLogEKeyField.datas_D_PF,
  },
];

export enum DataLogTabKey {
  lenslite = 0,
  sense = 1,
  shield = 2,
}

export enum DataLogHeaderTitle {
  LENSLITE = 1,
  SENSE = 2,
  SHIELD = 3,
}

// ---------------------------- QSENSE Data-log ---------------------------------------------------

export interface QsenseDataLogList {
  data?: ListCustom;
  fromDate: string;
  toDate: string;
  groupId: number;
  siteId?: number;
  macAddresses?: Array<string>;
  siteIds?: Array<number>;
  timeFrame?: { id: number; label: string; active: boolean };
  group?: CommonModel;
  site?: CommonModel;
}

export interface QsenseDataLogData {
  id: number;
  wifiMAC: string;
  time: Date;
  status: string;
  vrmS_A: number;
  vrmS_B: number;
  vrmS_C: number;
  irmS_A: number;
  irmS_B: number;
  irmS_C: number;
  active_Power_W_A: number;
  active_Power_W_B: number;
  active_Power_W_C: number;
  active_Energy_WHR_A: number;
  active_Energy_WHR_B: number;
  active_Energy_WHR_C: number;
  reactive_Power_VAR_A: number;
  reactive_Power_VAR_B: number;
  reactive_Power_VAR_C: number;
  reactive_Energy_AHHR_A: number;
  reactive_Energy_AHHR_B: number;
  reactive_Energy_AHHR_C: number;
  apparent_Power_VA_A: number;
  apparent_Power_VA_B: number;
  apparent_Power_VA_C: number;
  apparent_Energy_VARHR_A: number;
  apparent_Energy_VARHR_B: number;
  apparent_Energy_VARHR_C: number;
  vthD_A: number;
  vthD_B: number;
  vthD_C: number;
  ithD_A: number;
  ithD_B: number;
  ithD_C: number;
  power_Factor_A: number;
  power_Factor_B: number;
  power_Factor_C: number;
}

export class QsenseDataLogTable {
  page: number;
  size: number;
  total: number;
  data: QsenseDataLogData[];
}

export enum QsenseDataLogDropdownSuggession {
  macAddresses = "macAddresses",
  siteIds = "siteIds",
  groupId = "groupId",
}

export enum QsenseColumnName {
  ID = "id",
  WIFIMAC = "wifiMAC",
  TIME = "time",
  STATUS = "status",
  VRMS_A = "vrmS A",
  VRMS_B = "vrmS B",
  VRMS_C = "vrmS C",
  IRMS_A = "irmS A",
  IRMS_B = "irmS B",
  IRMS_C = "irmS C",
  ACTIVE_POWER_W_A = "active Power W A",
  ACTIVE_POWER_W_B = "active Power W B",
  ACTIVE_POWER_W_C = "active Power W C",
  ACTIVE_ENERGY_WHR_A = "active Energy WHR A",
  ACTIVE_ENERGY_WHR_B = "active Energy WHR B",
  ACTIVE_ENERGY_WHR_C = "active Energy WHR C",
  REACTIVE_POWER_VAR_A = "reactive Power VAR A",
  REACTIVE_POWER_VAR_B = "reactive Power VAR B",
  REACTIVE_POWER_VAR_C = "reactive Power VAR C",
  REACTIVE_POWER_AHHR_A = "reactive Energy AHHR A",
  REACTIVE_POWER_AHHR_B = "reactive Energy AHHR B",
  REACTIVE_POWER_AHHR_C = "reactive Energy AHHR C",
  APPARENT_POWER_VA_A = "apparent Power VA A",
  APPARENT_POWER_VA_B = "apparent Power VA B",
  APPARENT_POWER_VA_C = "apparent Power VA C",
  APPARENT_ENERGY_VARHR_A = "apparent Energy VARHR A",
  APPARENT_ENERGY_VARHR_B = "apparent Energy VARHR B",
  APPARENT_ENERGY_VARHR_C = "apparent Energy VARHR C",
  VTHD_A = "vthD A",
  VTHD_B = "vthD B",
  VTHD_C = "vthD C",
  ITHD_A = "ithD A",
  ITHD_B = "ithD B",
  ITHD_C = "ithD C",
  POWER_FACTOR_A = "power Factor A",
  POWER_FACTOR_B = "power Factor B",
  POWER_FACTOR_C = "power Factor C",
}

export interface QsenseColumnShowHide {
  name: QsenseColumnName;
  hide: boolean;
  key?: QsenseDataLogEKeyField;
}

export enum QsenseDataLogEKeyField {
  id = "id",
  wifiMAC = "wifiMAC",
  time = "time",
  status = "status",
  vrmS_A = "vrmS_A",
  vrmS_B = "vrmS_B",
  vrmS_C = "vrmS_C",
  irmS_A = "irmS_A",
  irmS_B = "irmS_B",
  irmS_C = "irmS_C",
  active_Power_W_A = "active_Power_W_A",
  active_Power_W_B = "active_Power_W_B",
  active_Power_W_C = "active_Power_W_C",
  active_Energy_WHR_A = "active_Energy_WHR_A",
  active_Energy_WHR_B = "active_Energy_WHR_B",
  active_Energy_WHR_C = "active_Energy_WHR_C",
  reactive_Power_VAR_A = "reactive_Power_VAR_A",
  reactive_Power_VAR_B = "reactive_Power_VAR_B",
  reactive_Power_VAR_C = "reactive_Power_VAR_C",
  reactive_Energy_AHHR_A = "reactive_Energy_AHHR_A",
  reactive_Energy_AHHR_B = "reactive_Energy_AHHR_B",
  reactive_Energy_AHHR_C = "reactive_Energy_AHHR_C",
  apparent_Power_VA_A = "apparent_Power_VA_A",
  apparent_Power_VA_B = "apparent_Power_VA_B",
  apparent_Power_VA_C = "apparent_Power_VA_C",
  apparent_Energy_VARHR_A = "apparent_Energy_VARHR_A",
  apparent_Energy_VARHR_B = "apparent_Energy_VARHR_B",
  apparent_Energy_VARHR_C = "apparent_Energy_VARHR_C",
  vthD_A = "vthD_A",
  vthD_B = "vthD_B",
  vthD_C = "vthD_C",
  ithD_A = "ithD_A",
  ithD_B = "ithD_B",
  ithD_C = "ithD_C",
  power_Factor_A = "power_Factor_A",
  power_Factor_B = "power_Factor_B",
  power_Factor_C = "power_Factor_C",
}

export const QsenseDataLogColumns: ColumnShowHide[] = [
  {
    name: QsenseColumnName.WIFIMAC,
    hide: false,
    key: QsenseDataLogEKeyField.wifiMAC,
  },
  {
    name: QsenseColumnName.TIME,
    hide: false,
    key: QsenseDataLogEKeyField.time,
  },
  {
    name: QsenseColumnName.STATUS,
    hide: false,
    key: QsenseDataLogEKeyField.status,
  },
  {
    name: QsenseColumnName.VRMS_A,
    hide: false,
    key: QsenseDataLogEKeyField.vrmS_A,
  },
  {
    name: QsenseColumnName.VRMS_B,
    hide: false,
    key: QsenseDataLogEKeyField.vrmS_B,
  },
  {
    name: QsenseColumnName.VRMS_C,
    hide: false,
    key: QsenseDataLogEKeyField.vrmS_C,
  },
  {
    name: QsenseColumnName.IRMS_A,
    hide: false,
    key: QsenseDataLogEKeyField.irmS_A,
  },
  {
    name: QsenseColumnName.IRMS_B,
    hide: false,
    key: QsenseDataLogEKeyField.irmS_B,
  },
  {
    name: QsenseColumnName.IRMS_C,
    hide: false,
    key: QsenseDataLogEKeyField.irmS_C,
  },
  {
    name: QsenseColumnName.ACTIVE_POWER_W_A,
    hide: false,
    key: QsenseDataLogEKeyField.active_Power_W_A,
  },
  {
    name: QsenseColumnName.ACTIVE_POWER_W_B,
    hide: false,
    key: QsenseDataLogEKeyField.active_Power_W_B,
  },
  {
    name: QsenseColumnName.ACTIVE_POWER_W_C,
    hide: false,
    key: QsenseDataLogEKeyField.active_Power_W_C,
  },
  {
    name: QsenseColumnName.ACTIVE_ENERGY_WHR_A,
    hide: false,
    key: QsenseDataLogEKeyField.active_Energy_WHR_A,
  },
  {
    name: QsenseColumnName.ACTIVE_ENERGY_WHR_B,
    hide: false,
    key: QsenseDataLogEKeyField.active_Energy_WHR_B,
  },
  {
    name: QsenseColumnName.ACTIVE_ENERGY_WHR_C,
    hide: false,
    key: QsenseDataLogEKeyField.active_Energy_WHR_C,
  },
  {
    name: QsenseColumnName.REACTIVE_POWER_VAR_A,
    hide: false,
    key: QsenseDataLogEKeyField.reactive_Power_VAR_A,
  },
  {
    name: QsenseColumnName.REACTIVE_POWER_VAR_B,
    hide: false,
    key: QsenseDataLogEKeyField.reactive_Power_VAR_B,
  },
  {
    name: QsenseColumnName.REACTIVE_POWER_VAR_C,
    hide: false,
    key: QsenseDataLogEKeyField.reactive_Power_VAR_C,
  },
  {
    name: QsenseColumnName.REACTIVE_POWER_AHHR_A,
    hide: false,
    key: QsenseDataLogEKeyField.reactive_Energy_AHHR_A,
  },
  {
    name: QsenseColumnName.REACTIVE_POWER_AHHR_B,
    hide: false,
    key: QsenseDataLogEKeyField.reactive_Energy_AHHR_B,
  },
  {
    name: QsenseColumnName.REACTIVE_POWER_AHHR_C,
    hide: false,
    key: QsenseDataLogEKeyField.reactive_Energy_AHHR_C,
  },
  {
    name: QsenseColumnName.APPARENT_POWER_VA_A,
    hide: false,
    key: QsenseDataLogEKeyField.apparent_Power_VA_A,
  },
  {
    name: QsenseColumnName.APPARENT_POWER_VA_B,
    hide: false,
    key: QsenseDataLogEKeyField.apparent_Power_VA_B,
  },
  {
    name: QsenseColumnName.APPARENT_POWER_VA_C,
    hide: false,
    key: QsenseDataLogEKeyField.apparent_Power_VA_C,
  },
  {
    name: QsenseColumnName.APPARENT_ENERGY_VARHR_A,
    hide: false,
    key: QsenseDataLogEKeyField.apparent_Energy_VARHR_A,
  },
  {
    name: QsenseColumnName.APPARENT_ENERGY_VARHR_B,
    hide: false,
    key: QsenseDataLogEKeyField.apparent_Energy_VARHR_B,
  },
  {
    name: QsenseColumnName.APPARENT_ENERGY_VARHR_C,
    hide: false,
    key: QsenseDataLogEKeyField.apparent_Energy_VARHR_C,
  },
  {
    name: QsenseColumnName.VTHD_A,
    hide: false,
    key: QsenseDataLogEKeyField.vthD_A,
  },
  {
    name: QsenseColumnName.VTHD_B,
    hide: false,
    key: QsenseDataLogEKeyField.vthD_B,
  },
  {
    name: QsenseColumnName.VTHD_C,
    hide: false,
    key: QsenseDataLogEKeyField.vthD_C,
  },
  {
    name: QsenseColumnName.ITHD_A,
    hide: false,
    key: QsenseDataLogEKeyField.ithD_A,
  },
  {
    name: QsenseColumnName.ITHD_B,
    hide: false,
    key: QsenseDataLogEKeyField.ithD_B,
  },
  {
    name: QsenseColumnName.ITHD_C,
    hide: false,
    key: QsenseDataLogEKeyField.ithD_C,
  },
  {
    name: QsenseColumnName.POWER_FACTOR_A,
    hide: false,
    key: QsenseDataLogEKeyField.power_Factor_A,
  },
  {
    name: QsenseColumnName.POWER_FACTOR_B,
    hide: false,
    key: QsenseDataLogEKeyField.power_Factor_B,
  },
  {
    name: QsenseColumnName.POWER_FACTOR_C,
    hide: false,
    key: QsenseDataLogEKeyField.power_Factor_C,
  },
];

export interface ExportDataLog {
  macAddresses: Array<string>;
  fromDate: string;
  toDate: string;
  groupId: number;
  siteId: number;
}