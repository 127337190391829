export const CONSTANTS = {
  API: {
    AUTHENTICATION: {
      LOGIN: "authentication/login",
      REFRESH: "authentication/refresh-authentication-result",
      INFO_USER: "authentication/current-user-info",
      LOGOUT: "authentication/logout",
      FORGOT_PASSWORD: "user-account/send-reset-password-link",
      SEND_SET_PASSWORD_LINK: "user-account/send-set-password-link",
      GET_USER_RESET_PASSWORD_DETAIL:
        "user-account/user-detail-by-set-password-token",
      RESET_PASSWORD: "user-account/set-password",
      CHANGE_PASSWORD: "user-account/change-password",
    },
    RESIDENTIAL: {
      COUNTRY: "country",
      STATE_CITY_EMIRATE: "state-emirate",
      STATE: 'country/state',
      SUBSTATE: 'country/state/substate',
      CITY: 'country/state/city',
      CREATE_RESIDENCE: "residence/create",
      UPDATE_RESIDENCE: "residence",
      DELETE_RESIDENCE: "residence",
      GET_SINGLE_RESIDENCE: "residence",
      LIST_RESIDENCE: "residence/search",
      ACTIVE_DEACTIVE_RESIDENCE: "residence",
      GET_ALL_RESIDENCE_NAME: "residence/search-name",
    },
    USERMANAGEMENT: {
      LIST_USERMANAGEMENT: "user/search",
      ACTIVE_DEACTIVE_USER: "user",
      GET_ROLE_LIST: "user-management/role/search",
    },
    DEVICEMANAGEMENT: {
      CREATE_DEVICE: "solar-device/create",
      LIST_DEVICE: "solar-device/search",
      ACTIVE_DEACTIVE_DEVICE: "solar-device",
      UPDATE_DEVICE: "solar-device",
      CHECKDEVICESNEXIST: "solar-device/check-exist-deviceSN",
      SITE_MAP_DETAIL: "site-map",
    },
    QSENSDEVICEMANAGEMENT: {
      CREATE_DEVICE: "qsense-device/create",
      LIST_DEVICE: "qsense-device/search",
      ACTIVE_DEACTIVE_DEVICE: "qsense-device",
      UPDATE_DEVICE: "qsense-device",
      CHECKDEVICESNEXIST: "qsense-device/check-exist-deviceSN",
      DEVICE_TYPE: "device-type/search-name",
      TRANSFORMER: "transformer/search-name",
      MAIN_DB: "main-db/search-name",
      SUB_DB: "sub-db/search-name",
      CIRCUIT: "circuit/search-name",
      GROUP_NAME: "group/search-name",
      SITE_NAME: "site/search-name/group/",
      LIST_QSENS_DEVICE: "qsense-device/search",
    },
    CUSTOMSOLAR: {
      LIST_ENERGY: "solar-energy/list",
      LIST_CARBON: "solar-carbon/list",
      LIST_FINANCIAL: "solar-financial/list",
      LIST_SEFL_SUFFICIENCY: "solar-self-sufficiency/list",
    },
    SOLAR: {
      GET_SOLAR_LIVE_STATUS: "solar-live-status/list",
      GET_WEATHER_DATA: "weather",
    },
    TECHNICALDATALOG: {
      LIST_DATALOG: "technical-data-Log/search",
      LIST_DEVICE: "technical-data-log/search-name",
      EXPORT_DATALOG: "technical-data-Log/download-file",
    },
    UNITCOST: {
      LIST_UNITCOST: "energy-price-slab/search",
      ADD_UNITCOST: "energy-price-slab/create",
      ACTIVE_DEACTIVE_UNITCOST: "energy-price-slab",
      UPDATE_UNIT_COST: "energy-price-slab",
    },
    AmazonS3: {
      GetMasterData: "",
      GetFileVersion: "",
      AddNewFileVersion: "",
    },
    USER: {
      CREATE_USER: "user/create-user",
      UPDATE_USER: "user/edit-user",
    },
    NOTIFICATION: {
      ADD_DEVICE: "device-notification",
      REMOVE_DEVICE: "device-notification",
      LIST_NOTIFICATION: "bell-notification",
      DELETE_NOTIFICATION: "bell-notification/",
      COUNT_UNREAD_NOTIFICATION: "bell-notification/count-unread-notification",
      MARK_ALL_AS_READ: "bell-notification/mark-all-as-read",
      SINGLE_READ: "bell-notification/",
    },
    RESIDENTDASHBOARD: {
      SOLAR_LIVE_STATUS: "dashboard/solar-live-status/",
      LIST_ENERGY: "dashboard/solar-energy/",
      LIST_CARBON: "dashboard/solar-carbon/",
      LIST_FINANCIAL: "dashboard/solar-financial/",
      LIST_SEFL_SUFFICIENCY: "dashboard/solar-self-sufficiency/",
      LIST_USER: "dashboard/user/",
    },
    GROUPMANAGEMENT: {
      BUSINESS_TYPE: "business-sector-type/search-name",
      SECTOR_TYPE: "sector-type/search-name",
      PROPERTY_SIZE_TYPE: "property-size-type/search-name",
      PROPERTY_SIZE: "property-size/search-name",
      BLOCK_NUMBER: "block-floor-number/search-name",
      LIST_GROUP: "group/search",
      CREATE_GROUP: "group/create",
      UPDATE_GROUP: "group",
      ACTIVE_DEACTIVE_GROUP: "group",
      LIST_SITE: "site/search",
      CREATE_SITE: "site/create",
      UPDATE_SITE: "site",
      ACTIVE_DEACTIVE_SITE: "site",
      LIST_TRANSFORMER: "transformer/search",
      GET_TRANSFORMER: "transformer",
      CREATE_TRANSFORMER: "transformer/create",
      UPDATE_TRANSFORMER: "transformer/update",
      DELETE_TRANSFORMER: "transformer",
    },
    PERFORMANCE: {
      PEER_PERFORMANCE: "performance/group/overview/peer-performance",
      SITE_PERFORMANCE: "performance/group/overview/site-performance",
      GROUP_PERFORMANCE_DATA: "performance/group/view/performance-data",
      SITE_PERFORMANCE_DATA: "performance/site/view/performance-data",
      MAP_VIEW: "performance/group/overview/map-view",
      GROUP_POWER_QUALITY_PERFORMANCE_DATA:
        "performance/group/view/performance-data/power-quality",
      SITE_POWER_QUALITY_PERFORMANCE_DATA:
        "performance/site/view/performance-data/power-quality",
      GROUP_EFFICIENCY_PERFORMANCE_DATA:
        "performance/group/view/performance-data/efficiency",
      SITE_EFFICIENCY_PERFORMANCE_DATA:
        "performance/site/view/performance-data/efficiency",
      GROUP_RANKING_AGAINST_GLOBAL:
        "performance/group/overview/ranking-against-global",
    },
    QSENSE_TECHNICALDATALOG: {
      LIST_QSENSE_DATALOG: "qsense-data-log/search",
      EXPORT_QSENSE_DATALOG: "qsense-data-log/download-file",
      MAC_ADDRESSES: "qsense-data-log/search-name",
    },
    QSENSE_DEVICE: {
      LIST_SITES: "device-management/search",
      LIST_SITE_DEVICE: "device-management/search-device",
    },
    ANALYTICS: {
      ANALYTICS_SEARCH: "analytics/search",
      ANALYTICS_SEARCH_NAME: "analytics/search-name",
      TOTAL: "Total"
    },
    ALERTLOG: {
      LIST_ALERT: "alert-log/search",
      CREATE_ALERT: "alert-log/create",
      CREATE_ALERT_COMMENT: "alert-log/comment",
      UPDATE_ALERT: "alert-log",
      UPDATE_ALERT_STATUS: "alert-log",
      GET_ALERTLOG_DETAILS: "alert-log/detailed-log",
    },
    GLOBAL_INTELLIGENCE: {
      LIST: "global-intelligence",
    },
    LIVE_STREAM: {
      LIST_STREAM: "live-stream/search",
    },
    CONTACT_US: {
      CONTACT_US_DETAILS: "contact-us/contact-us-detailes",
      CONTACT_US_EMAIL: "contact-us/contact-us-email",
    },
    REPORTS: {
      GET_REPORT_GROUP: "reports/report",
      GET_REPORT_NOTIFICATION: "reports/notification-report",
      GET_REPORT_SOLAR: "solar-report",
    },
    TARIFF_TYPE: {
      LIST_TARIFF_TYPE: "tariff-type",
    },
  },
  SignalR: {
    General: "/hub-signalR/general",
    DATA_REALTIME_UPDATE: "/hub-signalR/data-realtime-update",
  },
  LOCALSTORAGE_KEYS: {
    IS_REFRESH_TOKEN_IN_PROGRESS: "IS_REFRESH_TOKEN_IN_PROGRESS",
    TOKEN_KEY: "TOKEN_KEY",
    REFRESH_TOKEN: "REFRESH_TOKEN",
    REFRESH_TOKEN_SUBJECT_VALUE: "REFRESH_TOKEN_SUBJECT_VALUE",
    LOG_ERRORS: "LOG_ERRORS",
    LIST_TABS: "LIST_TABS",
    LIST_SUB_TABS: "LIST_SUB_TABS",
    LIST_DEVICE_SUB_TABS: "LIST_DEVICE_SUB_TABS",
    LIST_DATA_LOG_COLUMNS: "LIST_DATA_LOG_COLUMNS",
    LIST_NOTIFICATION_SUB_TABS: "LIST_NOTIFICATION_SUB_TABS",
    DATA_LOG_FILTER: "DATA_LOG_FILTER",
    RESIDENT_DASHBOARD_FILTER: "RESIDENT_DASHBOARD_FILTER",
    RESIDENT_SOLAR_FILTER: "RESIDENT_SOLAR_FILTER",
    GROUP_FILTER: "GROUP_FILTER",
    PERFORMANCE_FILTER: "ENERGY_PERFORMANCE_FILTER",
    ANALYTICS_FILTER: "ANALYTICS_FILTER",
    ANALYTICS_CURR_DATA: "ANALYTICS_CURRENT_DATA",
    ANALYTICS_COM_DATA: "ANALYTICS_COM_DATA",
    DATALOG_HEADER_TAB: "DATALOG_HEADER_TAB",
    QSENSE_LIST_DATA_LOG_COLUMNS: "QSENSE_LIST_DATA_LOG_COLUMNS",
    QSENSE_DATA_LOG_FILTER: "QSENSE_DATA_LOG_FILTER",
    SITE_DEVICE_FILTER: "SITE_DEVICE_FILTER",
    DEVICE_HEADER_TAB: "DEVICE_HEADER_TAB",
    GLOBAL_INDEX_FILTER: "GLOBAL_INDEX_FILTER",
    REPORTS_SUB_TABS: "REPORTS_SUB_TABS",
    REPORT_PERFORMANCE_TAB: "REPORT_PERFORMANCE_TAB",
    REPORT_NOTIFICATION_TAB: "REPORT_NOTIFICATION_TAB",
    REPORT_FILTER: "REPORT_FILTER",
    REPORT_BOTTOM_TAB: "REPORT_BOTTOM_TAB",
  },
  DEFAULT_KEYS: {
    DEFAULT_TAB_SHOW: 4,
    PAGE_DEFAULT_SIZE: 6,
    DEFAULT_PAGE_NUMBER: 1,
    PAGE_DEFAULT_SIZE_TEN: 10,
    PAGE_DEFAULT_SIZE_TWENTY: 20,
    DEBOUNCE_TIME: 300,
  },
  DEFAULT_DATE_FORMAT: "dd/MM/YYYY HH:mm a",
  DEFAULT_DATE_WITHOUT_TIME: "dd/MM/YYYY",
  DEFAULT_DATE_FORMAT_API: "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
  DEFAULT_DATE: "0001-01-01T00:00:00",
};
