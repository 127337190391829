import { createAction, props } from "@ngrx/store";
import { CommonModel, ResponseCustom, Series } from "src/main/shared/models";
import { Analytics, AnalyticsFilters, Data, DeviceDetail, DropdownResponse } from "src/main/shared/models/analytics";

const enum analyticsActionTypes {
    SET_GROUP_ANALYTICS = "[Analytics Page] Set Group",
    SET_SITE_ANALYTICS = "[Analytics Page] Set Site",
    SET_DEVICE_ANALYTICS = "[Analytics Page] Set Device",
    SET_CURRENCY = "[Analytics Page] Set Currency",
    LOAD_GROUP_ANALYTICS = "[Analytics] Load Group Ids",
    LOAD_GROUP_ANALYTICS_SUCCESS = "[Analytics] Load Group Ids Success",
    LOAD_GROUP_ANALYTICS_FAILURE = "[Analytics] Load Group Ids Failure",
    LOAD_SITE_ANALYTICS = "[Analytics] Load Site Ids",
    LOAD_SITE_ANALYTICS_SUCCESS = "[Analytics] Load Site Ids Success",
    LOAD_SITE_ANALYTICS_FAILURE = "[Analytics] Load Site Ids Failure",
    LOAD_COUNTRY_ANALYTICS = "[Analytics] Load All Countries",
    LOAD_COUNTRY_ANALYTICS_SUCCESS = "[Analytics] Load All Countries Success",
    LOAD_COUNTRY_ANALYTICS_FAILURE = "[Analytics] Load All Countries Failure",
    LOAD_DEVICE_ANALYTICS = "[Analytics] Load Device Ids",
    LOAD_DEVICE_ANALYTICS_SUCCESS = "[Analytics] Load Device Ids Success",
    LOAD_DEVICE_ANALYTICS_FAILURE = "[Analytics] Load Device Ids Failure",
    LOAD_CURRENT_ANALYTICS = "[Analytics Page] Load Current Analytics Data",
    LOAD_CURRENT_ANALYTICS_SUCCESS = "[Analytics Page] Load Current Analytics Data Success",
    LOAD_CURRENT_ANALYTICS_FAILURE = "[Analytics Page] Load Current Analytics Data Failure",
    LOAD_COMPARE_ANALYTICS = "[Analytics Page] Load Compare Analytics Data",
    LOAD_COMPARE_ANALYTICS_SUCCESS = "[Analytics Page] Load Compare Analytics Data Success",
    LOAD_COMPARE_ANALYTICS_FAILURE = "[Analytics Page] Load Compare Analytics Data Failure",
    LOAD_COMPARE_ANALYTICS_DEVICES = "[Analytics Page] Load Compare Analytics Devices",
    LOAD_COMPARE_ANALYTICS_DEVICES_SUCCESS = "[Analytics Page] Load Compare Analytics Devices Success",
    LOAD_COMPARE_ANALYTICS_DEVICES_FAILURE = "[Analytics Page] Load Compare Analytics Devices Failure",
    SET_ANALYTICS_FILTERS = "[Analytics Page] Save Analytics Filters",
    SET_CURRENT_SERIES_LEGEND = "[Analytics Page] Save Current Series Legend",
    SET_COMPARE_SERIES_LEGEND = "[Analytics Page] Save Compare Series Legend",
    SET_PHASE_SERIES_LEGEND = "[Analytics Page] Save Phase Series Legend",
    SET_ACTIVE_TAB = "[Analytics Page] Set Active Filter Tab",
    SET_CURRENT_DATA_LOADED = "[Analytics Page] Current Data Loaded",
    SET_COMPARE_DATA_LOADED = "[Analytics Page] Compare Data Loaded",
    REMOVE_SITE_IDS = "[Analytics Page] Remove Site Ids",
    REMOVE_DEVICE_IDS = "[Analytics Page] Remove Device Ids",
    REMOVE_CURRENT_CHART_DATA = "[Analytics Page] Remove Current Chart Data",
    REMOVE_COMPARE_CHART_DATA = "[Analytics Page] Remove Compare Chart Data",
    REMOVE_SERIES_LEGEND = "[Analytics Page] Remove Series Legend",
    REMOVE_COMPARE_SERIES_LEGEND = "[Analytics Page] Remove Compare Series Legend",
    REMOVE_SELECTED_SITE_ID = "[Analytics Page] Remove Selected Site Ids",
    REMOVE_SELECTED_DEVICE_ID = "[Analytics Page] Remove Selected Device Ids",
    RESET_ANALYTICS_STATE = "[Analytics Page] Reset State"

}

export const loadGroupIds = createAction(
    analyticsActionTypes.LOAD_GROUP_ANALYTICS,
    props<{ keyword?: string, size?: number, page?: number }>()
)

export const loadGroupIdsSuccess = createAction(
    analyticsActionTypes.LOAD_GROUP_ANALYTICS_SUCCESS,
    props<{ groupIds: DropdownResponse }>()
)

export const loadGroupIdsFailure = createAction(
    analyticsActionTypes.LOAD_GROUP_ANALYTICS_FAILURE,
    props<{ error: Partial<Error> }>()
)

export const loadSiteIds = createAction(
    analyticsActionTypes.LOAD_SITE_ANALYTICS,
    props<{ groupId: number, keyword?: string, page?: number, size?: number }>()
)

export const loadSiteIdsSuccess = createAction(
    analyticsActionTypes.LOAD_SITE_ANALYTICS_SUCCESS,
    props<{ siteIds: DropdownResponse }>()
)

export const loadSiteIdsFailure = createAction(
    analyticsActionTypes.LOAD_SITE_ANALYTICS_FAILURE,
    props<{ error: Partial<Error> }>()
)

export const loadDeviceIds = createAction(
    analyticsActionTypes.LOAD_DEVICE_ANALYTICS,
    props<
        {
            fromDate: string,
            toDate: string,
            groupId: number,
            siteId: number,
            keyword?: string,
            page?: number,
            size?: number
        }
    >()
)

export const loadDeviceIdsSuccess = createAction(
    analyticsActionTypes.LOAD_DEVICE_ANALYTICS_SUCCESS,
    props<{ deviceIds: DropdownResponse }>()
)

export const loadDeviceIdsFailure = createAction(
    analyticsActionTypes.LOAD_DEVICE_ANALYTICS_FAILURE,
    props<{ error: Partial<Error> }>()
)

export const loadCompareDeviceIds = createAction(
    analyticsActionTypes.LOAD_COMPARE_ANALYTICS_DEVICES,
    props<
        {
            fromDate: string,
            toDate: string,
            groupId: number,
            siteId: number,
            keyword?: string,
            page?: number,
            size?: number
        }
    >()
)

export const loadCompareDeviceIdsSuccess = createAction(
    analyticsActionTypes.LOAD_COMPARE_ANALYTICS_DEVICES_SUCCESS,
    props<{ compareDeviceNames: DropdownResponse }>()
)

export const loadCompareDeviceIdsFailure = createAction(
    analyticsActionTypes.LOAD_COMPARE_ANALYTICS_DEVICES_FAILURE,
    props<{ error: Partial<Error> }>()
)



export const loadAllCountries = createAction(
    analyticsActionTypes.LOAD_COUNTRY_ANALYTICS
)

export const loadAllCountriesSuccess = createAction(
    analyticsActionTypes.LOAD_COUNTRY_ANALYTICS_SUCCESS,
    props<{ countries: ResponseCustom }>()
)

export const loadAllCountriesFailure = createAction(
    analyticsActionTypes.LOAD_COUNTRY_ANALYTICS_FAILURE,
    props<{ error: Partial<Error> }>()
)

export const setGroup = createAction(
    analyticsActionTypes.SET_GROUP_ANALYTICS,
    props<{ group: CommonModel }>()
)

export const setSiteIds = createAction(
    analyticsActionTypes.SET_SITE_ANALYTICS,
    props<{ siteIds: CommonModel }>()
)

export const setDeviceId = createAction(
    analyticsActionTypes.SET_DEVICE_ANALYTICS,
    props<{ deviceId: CommonModel }>()
)


export const setCurrency = createAction(
    analyticsActionTypes.SET_CURRENCY,
    props<{ currency: CommonModel }>()
)

export const loadCurrentAnalyticsData = createAction(
    analyticsActionTypes.LOAD_CURRENT_ANALYTICS,
    props<{ payload: Analytics }>()
)

export const loadCurrentAnalyticsSuccess = createAction(
    analyticsActionTypes.LOAD_CURRENT_ANALYTICS_SUCCESS,
    props<{
        analyticsData: {
            data: Array<Data>;
            deviceDetail: DeviceDetail;
        }
    }>()
)

export const loadCurrentAnalyticsFailure = createAction(
    analyticsActionTypes.LOAD_CURRENT_ANALYTICS_FAILURE,
    props<{ error: Partial<Error> }>()
)

export const loadCompareAnalyticsData = createAction(
    analyticsActionTypes.LOAD_COMPARE_ANALYTICS,
    props<{ payload: Analytics }>()
)

export const loadCompareAnalyticsSuccess = createAction(
    analyticsActionTypes.LOAD_COMPARE_ANALYTICS_SUCCESS,
    props<{
        analyticsData: {
            data: Array<Data>;
            deviceDetail: DeviceDetail;
        }
    }>()
)

export const loadCompareAnalyticsFailure = createAction(
    analyticsActionTypes.LOAD_COMPARE_ANALYTICS_FAILURE,
    props<{ error: Partial<Error> }>()
)

export const setAnalyticsFilters = createAction(
    analyticsActionTypes.SET_ANALYTICS_FILTERS,
    props<{ filters: AnalyticsFilters }>()
)

export const setCurrentSeriesLegend = createAction(
    analyticsActionTypes.SET_CURRENT_SERIES_LEGEND,
    props<{ series: Series }>()
)

export const setCompareSeriesLegend = createAction(
    analyticsActionTypes.SET_COMPARE_SERIES_LEGEND,
    props<{ series: Series }>()
)

export const setActiveFilterTab = createAction(
    analyticsActionTypes.SET_ACTIVE_TAB,
    props<{ activeTabNo: number }>()
)


export const removeSiteIds = createAction(
    analyticsActionTypes.REMOVE_SITE_IDS,
)

export const removeDeviceIds = createAction(
    analyticsActionTypes.REMOVE_DEVICE_IDS,
)

export const removeCurrentChartData = createAction(
    analyticsActionTypes.REMOVE_CURRENT_CHART_DATA
)

export const removeCompareChartData = createAction(
    analyticsActionTypes.REMOVE_COMPARE_CHART_DATA
)

export const removeSeriesLegend = createAction(
    analyticsActionTypes.REMOVE_SERIES_LEGEND
)

export const removeCompareSeriesLegend = createAction(
    analyticsActionTypes.REMOVE_COMPARE_SERIES_LEGEND
)

export const removeSelectedSiteId = createAction(
    analyticsActionTypes.REMOVE_SELECTED_SITE_ID
)

export const removeSelectedDeviceId = createAction(
    analyticsActionTypes.REMOVE_SELECTED_DEVICE_ID
)

export const resetAnalyticsState = createAction(
    analyticsActionTypes.RESET_ANALYTICS_STATE
)

export const setCurrentDataLoaded = createAction(
    analyticsActionTypes.SET_CURRENT_DATA_LOADED,
    props<{ currentFlag: boolean }>()
)

export const setCompareDataLoaded = createAction(
    analyticsActionTypes.SET_COMPARE_DATA_LOADED,
    props<{ compareFlag: boolean }>()
)