import { createReducer, on } from "@ngrx/store";
import { AnalyticsFilters, Country, Data, DeviceDetail, DropdownResponse, TIME_FRAME_HOUR_LIST } from "src/main/shared/models/analytics";
import { AnalyticsActions } from "./action-types";
import { CommonModel, Series, TimeStamEnum } from "src/main/shared/models";

export const defaultFilters: AnalyticsFilters = {
    performanceFilters: {
        id: "11",
        value: "Qlens Score",
    },
    parameterFilters: {
        paramFilter: {
            id: "",
            value: "",
        },
        phaseFilter: "",
    },
    compareFilters: {
        filters: null,
        timeFrameId: 1,
        dateRange: {
            startDate: "",
            endDate: "",
        },
        timeStamp: null,
    },
    timeStamp: TimeStamEnum[0],
    timeHr: TIME_FRAME_HOUR_LIST[0],
    dateRange: {
        startDate: "",
        endDate: ""
    },
}

export interface AnalyticsState {
    groupNames: DropdownResponse,
    siteIdsNames: DropdownResponse,
    deviceNames: DropdownResponse,
    compareDeviceNames: DropdownResponse,
    countries: Country[],
    activeTabNo: number,
    group: CommonModel,
    siteIds: CommonModel,
    deviceId: CommonModel,
    currency: CommonModel,
    currentSeriesLegend: Series;
    compareSeriesLegend: Series;
    currentChartData: {
        data: Data[];
        deviceDetail: DeviceDetail;
    },
    compareChartData: {
        data: Data[];
        deviceDetail: DeviceDetail;
    },
    currentFlag: boolean;
    compareFlag: boolean;
    filters: AnalyticsFilters,
    error: Partial<Error>
}

export const initialState: AnalyticsState = {
    groupNames: null,
    siteIdsNames: null,
    deviceNames: null,
    compareDeviceNames: null,
    countries: null,
    activeTabNo: 0,
    group: null,
    siteIds: null,
    deviceId: null,
    currency: null,
    currentSeriesLegend: null,
    compareSeriesLegend: null,
    currentChartData: undefined,
    compareChartData: undefined,
    currentFlag: false,
    compareFlag: false,
    filters: defaultFilters,
    error: undefined
}

export const analyticsReducer = createReducer(
    initialState,
    on(AnalyticsActions.loadGroupIdsSuccess, (state, { groupIds }) => {
        return {
            ...state,
            groupNames: {
                ...groupIds,
                data: groupIds?.data.map((data: any) => ({
                    id: data.id,
                    label: data.name,
                    currencyCode: data.currencyCode,
                }))
            }
        }
    }),

    on(AnalyticsActions.loadGroupIdsFailure, (state, { error }) => {
        return {
            ...state,
            error: error
        }
    }),

    on(AnalyticsActions.loadSiteIdsSuccess, (state, { siteIds }) => {
        return {
            ...state,
            siteIdsNames: {
                ...siteIds,
                data: siteIds?.data.map((data: any) => ({
                    id: data.id,
                    label: data.name,
                }))
            }
        }
    }),
    on(AnalyticsActions.loadSiteIdsFailure, (state, { error }) => {
        return {
            ...state,
            error: error
        }
    }),

    on(AnalyticsActions.loadDeviceIdsSuccess, (state, { deviceIds }) => {
        return {
            ...state,
            deviceNames: {
                ...deviceIds,
                data: deviceIds?.data.map((data: any) => ({
                    id: data.id,
                    label: data.deviceTypeName,
                }))
            }
        }
    }),

    on(AnalyticsActions.loadDeviceIdsFailure, (state, { error }) => {
        return {
            ...state,
            error: error
        }
    }),

    on(AnalyticsActions.loadCompareDeviceIdsSuccess, (state, { compareDeviceNames }) => {
        return {
            ...state,
            compareDeviceNames: {
                ...compareDeviceNames,
                data: compareDeviceNames?.data.map((data: any) => ({
                    id: data.id,
                    label: data.deviceTypeName,
                }))
            }
        }
    }),

    on(AnalyticsActions.loadCompareDeviceIdsFailure, (state, { error }) => {
        return {
            ...state,
            error: error
        }
    }),


    on(AnalyticsActions.loadAllCountriesSuccess, (state, { countries }) => {
        return {
            ...state,
            countries: countries?.result.map((item) => ({
                id: item.id,
                label: `${item.name} - ${item.currency} - ${item.currencySybmol}`,
            })),
        }
    }),

    on(AnalyticsActions.loadAllCountriesFailure, (state, { error }) => {
        return {
            ...state,
            error: error
        }
    }),

    on(AnalyticsActions.setGroup, (state, { group }) => {
        return {
            ...state,
            group: group
        }
    }),

    on(AnalyticsActions.setSiteIds, (state, { siteIds }) => {
        return {
            ...state,
            siteIds: siteIds
        }
    }),

    on(AnalyticsActions.setDeviceId, (state, { deviceId }) => {
        return {
            ...state,
            deviceId: deviceId
        }
    }),

    on(AnalyticsActions.setCurrency, (state, { currency }) => {
        return {
            ...state,
            currency: currency
        }
    }),

    on(AnalyticsActions.loadCurrentAnalyticsSuccess, (state, { analyticsData }) => {
        return {
            ...state,
            currentFlag: true,
            currentChartData: analyticsData
        }
    }),

    on(AnalyticsActions.loadCompareAnalyticsSuccess, (state, { analyticsData }) => {
        return {
            ...state,
            compareFlag: true,
            compareChartData: analyticsData
        }
    }),

    on(AnalyticsActions.setAnalyticsFilters, (state, { filters }) => {
        return {
            ...state,
            filters: filters
        }
    }),

    on(AnalyticsActions.setCurrentSeriesLegend, (state, { series }) => {
        return {
            ...state,
            currentSeriesLegend: series
        }
    }),

    on(AnalyticsActions.setCompareSeriesLegend, (state, { series }) => {
        return {
            ...state,
            compareSeriesLegend: series
        }
    }),

    on(AnalyticsActions.setActiveFilterTab, (state, { activeTabNo }) => {
        return {
            ...state,
            activeTabNo
        }
    }),

    on(AnalyticsActions.removeSiteIds, (state) => {
        return {
            ...state,
            siteIdsNames: null
        }
    }),

    on(AnalyticsActions.removeDeviceIds, (state) => {
        return {
            ...state,
            deviceNames: null
        }
    }),

    on(AnalyticsActions.removeCurrentChartData, (state) => {
        return {
            ...state,
            currentChartData: null,
        }
    }),

    on(AnalyticsActions.removeCompareChartData, (state) => {
        return {
            ...state,
            compareChartData: null,
        }
    }),

    on(AnalyticsActions.removeSeriesLegend, (state) => {
        return {
            ...state,
            currentSeriesLegend: null,
        }
    }),

    on(AnalyticsActions.removeCompareSeriesLegend, (state) => {
        return {
            ...state,
            compareSeriesLegend: null,
        }
    }),

    on(AnalyticsActions.removeSelectedDeviceId, (state) => {
        return {
            ...state,
            deviceId: null,
        }
    }),

    on(AnalyticsActions.removeSelectedSiteId, (state) => {
        return {
            ...state,
            siteIds: null,
        }
    }),

    on(AnalyticsActions.setCurrentDataLoaded, (state, { currentFlag }) => {
        return {
            ...state,
            currentFlag
        }
    }),

    on(AnalyticsActions.setCompareDataLoaded, (state, { compareFlag }) => {
        return {
            ...state,
            compareFlag
        }
    }),

    on(AnalyticsActions.resetAnalyticsState, () => {
        return {
            ...initialState
        }
    })
)

