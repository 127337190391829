import { Injectable } from "@angular/core";
import { CONSTANTS } from "./../../shared/constants/constants";
import { TabModule } from "./../../shared/models";
import { Subject } from "rxjs";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class TabModuleService {
  private tabs: TabModule[];

  tabsWatch: Subject<TabModule[]> = new Subject<TabModule[]>();

  constructor(private router: Router) {
    this._getListTabs();
  }

  clearTab() {
    this.tabs = [];
    // this is alreay unscribed from tab component
    //  this.tabsWatch.unsubscribe()
  }
  getListTabs() {
    return this.tabs;
  }

  setTabActive(title: string, link: string) {
    const newTab = { title, link, active: true, removable: true };

    if (!this.tabs) {
      this.tabs = [];
      this.tabs.push(newTab);
    } else {
      const sameValue = this.tabs.findIndex((x) => x.link === newTab.link);
      if (sameValue === -1) {
        this.tabs.forEach((tab) => {
          tab.active = false;
        });
        this.tabs.unshift(newTab);
        if (newTab.link !== "/landing") {
          const indexLanding = this.tabs.findIndex(
            (tab) => tab.link === "/landing",
          );
          const landingItem = this.tabs.splice(indexLanding, 1);
          if (landingItem[0]) this.tabs.splice(0, 0, landingItem[0]);
        }
      } else {
        this.tabs.forEach((tab) => {
          tab.active = false;
        });
        this.tabs[sameValue].active = true;
      }
    }
    // if (link !== '/landing') {
    //   const indexLanding = this.tabs.findIndex(tab => tab.link === '/landing');
    //   if (indexLanding !== -1 && indexLanding < (this.tabs.length - 1)) {
    //     const landingItem = this.tabs.splice(indexLanding, 1);
    //     if (landingItem[0]) this.tabs.push(landingItem[0])
    //   }
    // }
    this.tabsWatch.next(this.tabs);
    this.saveTabs(this.tabs, CONSTANTS.LOCALSTORAGE_KEYS.LIST_TABS);
  }

  closeTabHeader(link: string) {
    let idxActive: boolean;
    this.tabs = this.getTabs(CONSTANTS.LOCALSTORAGE_KEYS.LIST_TABS) || [];
    let sameValue = this.tabs.findIndex((x) => x.link === link);
    if (sameValue !== -1) {
      idxActive = this.tabs[sameValue].active;
      this.tabs.splice(sameValue, 1);
    }

    if (this.tabs.length === 0) {
      localStorage.removeItem(CONSTANTS.LOCALSTORAGE_KEYS.LIST_TABS);
      this.router.navigate([""]);
    } else {
      if (idxActive) {
        this.tabs[0].active = true;
        this.router.navigate([this.tabs[0].link]);
      }
      this.tabsWatch.next(this.tabs);
      this.saveTabs(this.tabs, CONSTANTS.LOCALSTORAGE_KEYS.LIST_TABS);
    }
  }

  closeTabNewTab(link: string) {
    this.tabs = this.getTabs(CONSTANTS.LOCALSTORAGE_KEYS.LIST_TABS) || [];
    let sameValue = this.tabs.findIndex((x) => x.link === link);
    if (sameValue !== -1) {
      this.tabs.splice(sameValue, 1);
      this.saveTabs(this.tabs, CONSTANTS.LOCALSTORAGE_KEYS.LIST_TABS);
    }
  }

  private _getListTabs() {
    this.tabs = this.getTabs(CONSTANTS.LOCALSTORAGE_KEYS.LIST_TABS) || [];
    return this.tabs;
  }
  private getTabs(key: string) {
    let tabs = [];
    try {
      tabs = JSON.parse(localStorage.getItem(key));
    } catch (_) {}
    return tabs;
  }

  private saveTabs(tabs: any[], key: string) {
    localStorage.setItem(key, JSON.stringify(tabs));
  }
}
